@media(max-width:  768px){
	.page-height {
		overflow-y: auto;
		overflow-x: hidden;
		min-height: calc(100vh - 123px);
		height: calc(100vh - 123px);
		max-height: calc(100vh - 123px);
	}
}

@media (min-width: 768px) { 

	body {
		overflow-x: hidden;
	}

	.page-height {
		overflow-y: auto;
		overflow-x: hidden;
		min-height: calc(100vh - 110px);
		height: calc(100vh - 123px);
		max-height: calc(100vh - 110px);
	}
}


#sidebar-control {
	top: 15px;
	left: 15px;
	position: sticky;
	display:  inline;
	background-color: #fff;
	z-index: 500;
}