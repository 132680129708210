@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Dosis:200,400,500,600);
.loader {
    color: #50a1ff;
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  }
  @-webkit-keyframes load6 {
    0% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes load6 {
    0% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @-webkit-keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
.loader {
    color: #50a1ff;
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  }
  @-webkit-keyframes load6 {
    0% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes load6 {
    0% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
              box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @-webkit-keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

.force-font {
  font-family: "Open Sans", sans-serif !important;
  font-size: 0.9375rem !important; 
}
@media (max-width: 768px) { 

  .map-container {
    min-height: 400px;
    width: 100%;
  }

}

@media (min-width: 768px) { 

  .map-container {
    min-height: 100%;
    width: 100%;
  }

}

.sandbox-wrapper > div {
  min-height: 400px;
}

.marker-label {
	margin-top: -16px;
	font-weight: bold;
	/*background-color: rgb(254, 67, 53);*/
}



/*
 * Explanation why the layout looks so complicated:
 * The UI container needs a position (absolute or relative) to prevent z-index issues (DomMarker on top of UI)
 * Therefore it has these additional styles:
 *    position: absolute;
 *    width: 100%;
 *    height: 100%;
 * To prevent that the UI container captures all events the container is displaced by
 *   left: 100%;
 * To neutralize the displacement for the UI elements within the UI container the following adjustments are needed:
 *  - InfoBubble (.H_ib):            left: -100%;
 *  - left anchor (.H_l_left):       margin-left: -100%;
 *  - center anchor (.H_l_center):   left: -50%;            (was left: 50%)
 *  - right anchor (.H_l_right):     right: 100%;           (was right: 0)
 *                                        margin-left: -100%;
 */

.H_ui {
  font-size: 10px;
  font-family: "Lucida Grande", Arial, Helvetica, sans-serif;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  /* position ui on top of imprint to make both clickable */
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100%;
}
.H_ui * {
  /* normalize in case some other normalization CSS likes things differently */
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  -moz-box-sizing: content-box;
}
.H_noevs {
  pointer-events: none;
}

/*
 * Layout
 */
.H_l_left {
  position: absolute;
  left: 16px;
  margin-left: -100%;
}
.H_l_center {
  position: absolute;
  left: -50%;
}
.H_l_right {
  position: absolute;
  right: -webkit-calc(100% + 16px);
  right: calc(100% + 16px);
  margin-left: -100%;
}
.H_l_top {
  top: 16px;
}
.H_l_middle {
  top: 50%;
}
.H_l_bottom {
  bottom: 16px;
}

/* Fix MAPSJS-579 for modern browsers */
[class^=H_l_] {
    pointer-events: none;
}
.H_ctl {
    /* hack for IE9-10, auto doesn't work for them */
    pointer-events: visiblePainted;
    pointer-events: auto;
}

.H_l_horizontal .H_ctl {
  float: left;
}

.H_l_anchor {
  clear: both;
  float: right;
}

.H_l_vertical .H_ctl {
  clear: both;
}

.H_l_right .H_l_vertical .H_ctl {
  float: right;
}

.H_l_right.H_l_middle.H_l_vertical .H_ctl{
  float: right;
}

/**
 *  Element styles
 */

.H_ctl {
  margin: .8em;
  position: relative;
  -ms-touch-action: none;
}

.H_btn {
  cursor: pointer;
}

.H_grp .H_btn,
.H_rdo_buttons .H_btn {
    -webkit-box-shadow: none;
            box-shadow: none;
}
.H_grp .H_btn.H_active,
.H_rdo_buttons .H_btn.H_active {
  background: none;
}

.H_btn {
  -webkit-box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
          box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
  border-radius: 0.5em;
  width: 4em;
  height: 4em;
  background: #fff;
}

.H_disabled,
.H_disabled:hover {
  cursor: default;
}

.H_rdo_title {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  background-color: #575B63;
  color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 5px 5px 0 0;
  margin-bottom: 8px;
  cursor: default;
}
.H_rdo ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
}

/**
 *   Base Elements
 */
.H_ctl.H_grp {
  background: #fff;
  -webkit-box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
          box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
  border-radius: 0.5em;
}
/* Button divider */
.H_zoom .H_el {
  position: relative;
}
.H_l_vertical .H_zoom .H_el:last-child::after,
.H_l_horizontal .H_zoom .H_el:last-child::after {
  content: none;
}

.H_l_vertical .H_zoom .H_el {
  margin-bottom: 0.1em;
}
.H_l_vertical .H_zoom .H_el:last-child {
  margin-bottom: 0;
}
.H_l_vertical .H_zoom .H_el::after {
  content: "";
  position: absolute;
  width: 2.6em;
  height: 0.1em;
  bottom: -0.1em;
  left: 0.7em;
  background: rgba(15, 22, 33, 0.1);
}

.H_l_horizontal .H_zoom .H_el {
  margin-right: 0.1em;
}
.H_l_horizontal .H_zoom .H_el:last-child {
  margin-right: 0;
}
.H_l_horizontal .H_zoom .H_el::after {
  content: "";
  position: absolute;
  width: 0.1em;
  height: 2.6em;
  top: 0.7em;
  right: -0.1em;
  background: rgba(15, 22, 33, 0.1);
}
/* End: Button divider */
.H_l_horizontal .H_grp .H_btn,
.H_l_vertical .H_ctl {
  float: left;
}


/** Menu panel */
.H_overlay {
  font-size: 14px;
  color: rgba(15, 22, 33, 0.6);
  -webkit-box-shadow: 0px 0 4px 0 rgba(15, 22, 33, 0.6);
          box-shadow: 0px 0 4px 0 rgba(15, 22, 33, 0.6);
  border-radius: 5px;
  position: absolute;
  min-width: 200px;
  background: #fff;
  display: none;
  z-index: 100;
  padding-bottom: 4px;
}

.H_overlay .H_separator {
  content: "";
  position: relative;
  display: block;
  margin: 8px 16px 8px 16px;
  height: 1px;
  background: rgba(15, 22, 33, 0.1);
}

.H_overlay .H_btn,
.H_overlay .H_rdo li {
  width: 184px;
  height: 24px;
  line-height: 24px;
  padding: 8px 16px;
}
.H_overlay .H_btn{
  border-radius: 0px;
}

.H_overlay .H_btn:hover,
.H_overlay .H_rdo li:hover {
  color: rgba(15, 22, 33, 0.8);
}

.H_overlay .H_btn.H_disabled,
.H_overlay .H_rdo.H_disabled li,
.H_overlay .H_btn.H_disabled:hover,
.H_overlay .H_rdo.H_disabled li:hover {
  color: rgba(15, 22, 33, 0.2);
}

.H_overlay .H_btn.H_active,
.H_overlay .H_rdo.H_active li,
.H_overlay .H_btn.H_active:hover,
.H_overlay .H_rdo.H_active li:hover {
  color: #0F1621;
}

.H_overlay>*:last-child {
  clear: both;
}
.H_overlay>.H_btn {
  white-space: nowrap;
}

.H_overlay.H_open {
  display: block;
}

.H_overlay::before, .H_overlay::after {
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}
.H_overlay.H_left::before {
  border-width: 10px 10px 10px 0;
  border-color: transparent rgba(15, 22, 33, 0.2) transparent transparent;
  left: -12px;
}
.H_overlay.H_left::after {
  border-width: 10px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  left: -10px;
}
.H_overlay.H_top.H_left::after {
  border-color: transparent #575B63 transparent transparent;
}

.H_overlay.H_right::before {
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgba(15, 22, 33, 0.2);
  left: -webkit-calc(100% + 1px);
  left: calc(100% + 1px);
}
.H_overlay.H_right::after {
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #fff;
  left: 100%;
}
.H_overlay.H_top.H_right::after {
  border-color: transparent transparent transparent #575B63;
}

.H_overlay.H_top::before,
.H_overlay.H_top::after {
  top: 10px;
}
.H_overlay.H_bottom::before,
.H_overlay.H_bottom::after {
  bottom: 10px;
}
.H_overlay.H_middle::before,
.H_overlay.H_middle::after {
  top: 50%;
  margin-top: -10px;
}

.H_overlay.H_top.H_center::before {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent rgba(15, 22, 33, 0.2) transparent;
  top: -11px;
  left: 50%;
  margin-left: -10px;
}
.H_overlay.H_top.H_center::after {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #575B63 transparent;
  top: -10px;
  left: 50%;
  margin-left: -10px;
}

.H_overlay.H_bottom.H_center::before {
  border-width: 10px 10px 0 10px;
  border-color: rgba(15, 22, 33, 0.2) transparent transparent transparent;
  bottom: -11px;
  left: 50%;
  margin-left: -10px;
}
.H_overlay.H_bottom.H_center::after {
  border-width: 10px 10px 0 10px;
  border-color: #fff transparent transparent transparent;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
}


/** InfoBubble */
.H_ib {
  position: absolute;
  left: .91em;
  left: -100%;
}
/*.H_ib_tail {
  position: absolute;
  width: 20px;
  height: 10px;
  margin: -10px -10px;
  margin-top: -30px;
}

.H_ib_tail::before{
  bottom: -1px;
  border-width: 10px;
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  right: 0px;
}

.H_ib_tail::after{
  bottom: 0;
  position: absolute;
  display: block;
  content: "";
  border-color: white;
  border-style: solid;
  border-width: 10px;
}

.H_ib.H_ib_top .H_ib_tail::after {
  border-width: 10px 10px 0px 10px;
  border-color: white transparent;
}

.H_ib.H_ib_top .H_ib_tail::before {
  border-top-color: rgba(15, 22, 33, 0.2);
  border-bottom-width: 0px;
}

.H_ib_notail .H_ib_tail {
  display: none;
}*/
.H_ib_body {
  background: white;
  position: absolute;
  bottom: 25px;
  padding: 0;
  right: 0px;
  border-radius: 5px;
  margin-right: -3em;
  -webkit-box-shadow: 0px 0 4px 0 rgba(15, 22, 33, 0.6);
          box-shadow: 0px 0 4px 0 rgba(15, 22, 33, 0.6);
  margin-bottom: 0.5em;
  width:  150px;
}

.H_ib_close {
  font-size: .6em;
  position: absolute;
  right: 12px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  top: 12px;
  z-index: 1;
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.H_ib_close svg.H_icon {
    top: 0;
    -webkit-transform: none;
            transform: none;
    width: auto;
    height: auto;
}

.H_ib_noclose .H_ib_close {
  display: none;
}
.H_ib_noclose .H_ib_body {
  padding: 0 0 0 0;
}

.H_ib_content {
  min-width: 6em;
  font: 14px "Lucida Grande", Arial, Helvetica, sans-serif;
  line-height: 24px;
  margin: 16px 28px 20px 16px;
  color: rgba(15,22,33,.8);
  user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -o-user-select: text;
  -ms-user-select: text;
}


.H_ib_body {
  background: white;
  position: absolute;
  border: 1px solid #f1f2f3;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.05);
          box-shadow: 0 0 15px rgba(0,0,0,0.05);
  -webkit-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
  z-index: 1010;
  -webkit-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  opacity:  0.8;
  padding:  0.5rem !important;
  font-family: "Open Sans",sans-serif;
  font-size: .9375rem;
  font-weight: 300;
  line-height: 1.9;
  color: #757575;
  text-align: left;
  }

.H_ib_content {
 display: -webkit-flex;
 display: flex;
   margin:  0 !important;
 -webkit-align-items: flex-start;
         align-items: flex-start;
 font-family: "Open Sans",sans-serif;
  font-size: .9375rem;
  font-weight: 300;
  line-height: 1.9;
  color: #757575;
  text-align: left;
}

.H_ib_close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1rem;
  font-weight: 100;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
          appearance: none;
  float: right;
  font-size: 1.40625rem;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: .5;
}

/*.H_ib_close > svg.H_icon {
  display:  none !important;
}

.H_ib_close > svg.H_icon {
  content: "&times";
}*/



/*##################################################  SLIDER  ########################################################*/

.H_l_horizontal .H_zoom_slider {
  min-width: 262px;
}
.H_slider {
  cursor: pointer;
}
.H_l_horizontal.H_slider {
  float: left;
  height: 4em;
  width: auto;
  padding: 0 1em;
}

.H_slider .H_slider_track {
  width: 0.4em;
  height: 100%;
}

.H_l_horizontal.H_slider .H_slider_track {
  height: 0.4em;
  width: 100%;
}

.H_l_horizontal.H_slider .H_slider_cont {
  height: 100%;
}

.H_l_horizontal.H_slider .H_slider_knob_cont {
  margin-top: -0.4em;
}

.H_slider {
  background-color: #fff;
  padding: 1em 0em;
  width: 4em;
}


.H_slider .H_slider_cont {
  position: relative;
}

.H_slider .H_slider_knob_cont,
.H_slider .H_slider_knob_halo {
  width: 1.8em;
  height: 1.8em;
  margin-left: 0em;
  border-radius:9em;
}


.H_slider .H_slider_knob {
  width: 1.2em;
  height: 1.2em;
  background-color: white;
  border-radius:9em;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  -webkit-box-shadow: 0em 0 0.5em 0 rgba(15, 22, 33, 0.6);
          box-shadow: 0em 0 0.5em 0 rgba(15, 22, 33, 0.6);
  position: absolute;
}

.H_slider:hover .H_slider_knob {
  -webkit-box-shadow: 0em 0 0.5em 0 rgba(15, 22, 33, 0.8);
          box-shadow: 0em 0 0.5em 0 rgba(15, 22, 33, 0.8);
}
.H_slider.H_disabled .H_slider_knob {
  -webkit-box-shadow: 0em 0 0.5em 0 rgba(15, 22, 33, 0.2);
          box-shadow: 0em 0 0.5em 0 rgba(15, 22, 33, 0.2);
}
.H_slider.H_slider_active .H_slider_knob {
  -webkit-box-shadow: 0em 0 0.5em 0 rgba(15, 22, 33, 1);
          box-shadow: 0em 0 0.5em 0 rgba(15, 22, 33, 1);
}

.H_slider:hover .H_slider_track {
  background-color: rgba(15, 22, 33, 0.8);
}

.H_disabled .H_slider_track {
  background-color: rgba(15, 22, 33, 0.2);
}
.H_slider:hover .H_slider_track_active {
  background-color: rgba(0, 182, 178, 0.8);
}
.H_disabled .H_slider_track_active {
  background-color: rgba(0, 182, 178, 0.2);
}
.H_slider.H_slider_active .H_slider_track {
  background-color: rgba(15, 22, 33, 1.0);
}
.H_slider.H_slider_active .H_slider_track_active {
  background-color: rgba(0, 182, 178, 1.0);
}

.H_slider .H_slider_track,
.H_slider .H_slider_knob_cont{
  position:relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.H_slider .H_slider_track {
  background-color: rgba(15, 22, 33, 0.6);
  overflow: hidden;
}
.H_slider .H_slider_track_active {
  background-color: #00B6B2;
  position: absolute;
  -webkit-transform: translate(-50%,0%);
          transform: translate(-50%,0%);
}

.H_slider.H_disabled .H_slider_track {
  background-color: rgba(15, 22, 33, 0.2);
}
.H_slider.H_disabled .H_slider_track_active {
  background-color: #bae2e3;
}

.H_slider.H_l_horizontal .H_slider_track_active {
  -webkit-transform: translate(-200%, -50%);
          transform: translate(-200%, -50%);
}

.H_slider.H_disabled {
  cursor: default;
}

/*###############################################  CONTEXT MENU  #####################################################*/
.H_context_menu {
  font-size: 14px;
  min-width: 158px;
  max-width: 40%;
  -webkit-box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
          box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
  position: absolute;
  left: -100%;
  top: 0;
  color: rgba(15, 22, 33, 0.6);
  background-color: #fff;
  border-radius: 5px;
  padding: 16px 16px 4px 16px;
  -moz-user-select: initial;
  -khtml-user-select: initial;
  -webkit-user-select: initial;
  -o-user-select: initial;
  -ms-user-select: initial;
  z-index: 200;
}

.H_context_menu_closed {
    display: none;
}

.H_context_menu_item {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 24px;
  margin-bottom: 16px;
  outline: none;
}

.H_context_menu_item.clickable:hover {
  color: rgba(15, 22, 33, 0.8);
  cursor: pointer;
}
.H_context_menu_item.disabled:hover,
.H_context_menu_item.disabled {
    background: transparent !important;
    color: rgba(15, 22, 33, 0.2);
    cursor: default !important;

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
}
.H_context_menu_item_separator {
    height: 0;
    border-top: 1px solid rgba(15, 22, 33, 0.1);
    padding-bottom: 16px;
    line-height: 0;
    font-size: 0;
}


/*#################################################  SCALE BAR  ######################################################*/
.H_scalebar {
  margin-top: 36px;
  -webkit-box-shadow: none;
          box-shadow: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-shadow:
    -1px -1px 0 rgba(255, 255, 255, 0.7),
    1px -1px 0 rgba(255, 255, 255, 0.7),
    -1px 1px 0 rgba(255, 255, 255, 0.7),
    1px 1px 0 rgba(255, 255, 255, 0.7);
}


/*###################################  DISTANCE MEASUREMENT AND TRAFFIC INCIDENTS ####################################*/

.H_tib_content {
  width: 25em;
  position: relative;
  margin: -16px -28px -20px -16px;
}
.H_tib .H_tib_desc { padding: 0px 16px 20px 16px }
.H_tib .H_tib_time {color: rgba(15,22,33,.4);margin-top: 0.8em;}
.H_tib_right { float:right; }

.H_tib .H_btn > svg.H_icon {
  fill: rgba(255,255,255, .6);
}

.H_tib .H_btn:hover > svg.H_icon {
  fill: white;
}

.H_dm_label {
  font: 12px "Lucida Grande", Arial, Helvetica, sans-serif;
  color: black;
  text-shadow: 1px 1px .5px #FFF, 1px -1px .5px #FFF, -1px 1px .5px #FFF, -1px -1px .5px #FFF;
  white-space: nowrap;
  margin-left: 12px;
  margin-top: -7px;
  /* This will not work on IE9, but it is accepted! */
  pointer-events: none;
}


/*###################################################  ICON  #########################################################*/
svg.H_icon {
  display: block;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin:auto;
  width: 24px;
  height: 24px;
  fill: rgba(15, 22, 33, 0.6);
}
svg.H_icon .H_icon_stroke {
  stroke: rgba(15, 22, 33, 0.6);
  fill: none;
}
.H_btn:hover > svg.H_icon {
  fill: rgba(15, 22, 33, 0.8);
}
.H_btn:hover > svg.H_icon .H_icon_stroke {
  stroke: rgba(15, 22, 33, 0.8);
}
.H_btn.H_active {
  background-color: #CFD0D3;
}
.H_rdo .H_btn.H_active {
  background: none;
}

.H_active > svg.H_icon,
.H_active:hover > svg.H_icon {
  fill: #0F1621 !important;
}
.H_active > svg.H_icon .H_icon_stroke,
.H_active:hover > svg.H_icon .H_icon_stroke {
  stroke: #0F1621;
}

.H_disabled svg.H_icon,
.H_disabled:hover svg.H_icon {
  fill: rgba(15, 22, 33, 0.2);
  cursor: default;
}
.H_disabled svg.H_icon .H_icon_stroke,
.H_disabled:hover svg.H_icon .H_icon_stroke {
  stroke: rgba(15, 22, 33, 0.2);
}

/*###############################################  OVERVIEW MAP  #####################################################*/
.H_overview {
  -webkit-transition: width 0.2s,height 0.2s,margin-top 0.2s, padding 0.2s;
  transition: width 0.2s,height 0.2s,margin-top 0.2s, padding 0.2s;
  width: 0em;
  height: 0em;
  overflow: hidden;
  cursor: default;
  position: absolute;
  margin: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
          box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
}

.H_l_vertical .H_overview_active {
    margin: auto 5px;
}

.H_l_horizontal .H_overview_active {
  margin: 5px auto;
}

.H_l_center .H_overview {
  left: -9999px;
  right: -9999px;
}

.H_l_middle .H_overview {
  top: -9999px;
  bottom: -9999px;
}

.H_l_right .H_overview {
  right: 100%;
}

.H_l_left .H_overview {
  left: 100%;
}

.H_l_bottom .H_overview {
  bottom: 0;
}
.H_l_center.H_l_bottom .H_overview {
  bottom: 100%;
}

.H_l_top .H_overview {
  top: 0;
}
.H_l_center.H_l_top .H_overview {
  top: 100%;
}

.H_overview .H_overview_map {
  background-color: rgba(256,256,256,0.6);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
          box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
}


.H_overview_map .H_ui {
  display: none;
}

.H_zoom_lasso {
  position: absolute;
  display: none;
  -webkit-box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
          box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
  z-index: 100000;
  background-color: rgba(15, 22, 33, 0.2);
}



.react-tel-input .special-label {
	display: none;
}

.flag-dropdown {
	display: none;
}
.datePicker100Width, 
.datePicker100Width div.react-datepicker-wrapper, 
.datePicker100Width div.react-datepicker-wrapper div.react-datepicker__input-container,
.datePicker100Width div.react-datepicker-wrapper div.react-datepicker__input-container input {
   width: 100% !important;
   max-width: 100% !important;

}
.help-block { 
    font-size: 12px;
    color: #ff0000;
    background-color: rgba(255, 200, 200, 10);
    text-align: center;
}

.Login {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.Login-content {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    padding: 40px;
}

.background {
    width: 400px !important;
    background-color: white;
    padding: 2em !important;
}

.login-title {
    font-family: Dosis,sans-serif;
    font-size: 1.23047rem;
    font-weight: 400;
}

.form-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 0; 
}

@media (min-width: 650px ) {
    .Login {
        background-position: center center;
    }

    .Login-content {
        -webkit-justify-content: center;
                justify-content: center;
    }
}

.slide-right-base {
	visibility: hidden;
	 -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); 
}

.slide-right-enter {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); 
    visibility: visible !important;
}

.slide-right-enter-active {
  visibility: visible !important;
	opacity: 1;
	-webkit-transform: translate(0, 0);
	        transform: translate(0, 0);
    -webkit-transition: 0.4s ease-out;
    transition: 0.4s ease-out;
}

.slide-right-enter-done {
  visibility: visible !important;
	opacity: 1;
	-webkit-transform: translate(0, 0);
	        transform: translate(0, 0);
}

.slide-right-exit {
    float: none;
    padding: 0.75rem 1rem;
    font-weight: 100;
    font-size: 2.5rem;
    -webkit-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
    visibility: visible !important;
}

.slide-right-exit-active {
	visibility: hidden !important;
}

.slide-right-exit-done {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); 
    visibility: visible !important;
}
@media(max-width:  768px){
	.page-height {
		overflow-y: auto;
		overflow-x: hidden;
		min-height: -webkit-calc(100vh - 123px);
		min-height: calc(100vh - 123px);
		height: -webkit-calc(100vh - 123px);
		height: calc(100vh - 123px);
		max-height: -webkit-calc(100vh - 123px);
		max-height: calc(100vh - 123px);
	}
}

@media (min-width: 768px) { 

	body {
		overflow-x: hidden;
	}

	.page-height {
		overflow-y: auto;
		overflow-x: hidden;
		min-height: -webkit-calc(100vh - 110px);
		min-height: calc(100vh - 110px);
		height: -webkit-calc(100vh - 123px);
		height: calc(100vh - 123px);
		max-height: -webkit-calc(100vh - 110px);
		max-height: calc(100vh - 110px);
	}
}


#sidebar-control {
	top: 15px;
	left: 15px;
	position: -webkit-sticky;
	position: sticky;
	display:  inline;
	background-color: #fff;
	z-index: 500;
}
